<template>
  <div class="subject">
            <div class="top-box">
              <div class="top-title">新高考选科</div>
              <div class="btn-box">
                <div class="btn" @click="handleSearchClick"><i class="el-icon-search"></i> 查询</div>
              </div>
            </div>
            <el-tabs v-model="tabSelect" type="card" class="form-group" @tab-click="handleTabClick">
                <el-tab-pane label="大学选科科目查询" name="schoolTab">
                    <el-row>
                        <el-col :span="6" class="form-item">
                            <div class="label">省份</div>
                            <div class="input-box">
                                <el-select class="form-control" v-model="province"
                                v-loading="provinceListLoading"
                                element-loading-spinner="el-icon-loading">
                                  <el-option key="0" label="请选择..." value=""></el-option>
                                  <el-option
                                    v-for="item in provinceList"
                                    :key="item.key"
                                    :label="item.label"
                                    :value="item.value"
                                  ></el-option>
                              </el-select>
                            </div>
                        </el-col>
                        <el-col :span="6" class="form-item">
                            <div class="label">年份</div>
                            <div class="input-box">
                                <el-select class="form-control" v-model="year"
                                v-loading="yearListLoading"
                                element-loading-spinner="el-icon-loading">
                                  <el-option key="0" label="请选择..." value=""></el-option>
                                  <el-option
                                  v-for="item in yearList"
                                  :key="item.key"
                                  :label="item.label"
                                  :value="item.value"
                                  ></el-option>
                                </el-select>
                            </div>
                        </el-col>
                        <el-col :span="6" class="form-item">
                            <div class="label">大学名称</div>
                            <div class="input-box">
                                <el-input v-model="schoolName" placeholder="请输入大学名称(仅限中文)" class="form-control"></el-input>
                            </div>
                        </el-col>
                    </el-row>
                    <div class="table-container-body">
                        <el-table :data="schoolTableData" height="500" stripe class="table-body" 
                            :header-cell-style="{background:'#e6e6e6', color: '#646464'}"
                            v-loading="schoolTableLoading">
                            <el-table-column prop="majorName" label="专业名称">
                            </el-table-column>
                            <el-table-column prop="conatainMajor" label="所含专业" width="240">
                            </el-table-column>
                            <el-table-column prop="preferredRequest" label="首选科目要求" width="160">
                            </el-table-column>
                            <el-table-column prop="ReElectionRequest" label="再选科目要求" width="160">
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="table-container-footer">
                        <el-pagination
                            layout="total, prev, pager, next, sizes, jumper"
                            :page-sizes="[10, 50, 100, 500]"
                            :page-size="schoolPageSize"
                            :total="schoolTableTotal"
                            :current-page="schoolPageNo"
                            @current-change="schoolPageChange"
                            @size-change="schoolSizeChange"
                        >
                        </el-pagination>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="专业选科科目查询" name="majorTab">
                    <el-row>
                        <el-col :span="6" class="form-item">
                            <div class="label">省份</div>
                            <div class="input-box">
                                <el-select class="form-control" v-model="province"
                                v-loading="provinceListLoading"
                                element-loading-spinner="el-icon-loading">
                                  <el-option key="0" label="请选择..." value=""></el-option>
                                  <el-option
                                    v-for="item in provinceList"
                                    :key="item.key"
                                    :label="item.label"
                                    :value="item.value"
                                  ></el-option>
                              </el-select>
                            </div>
                        </el-col>
                        <el-col :span="6" class="form-item">
                            <div class="label">年份</div>
                            <div class="input-box">
                                <el-select class="form-control" v-model="year"
                                v-loading="yearListLoading"
                                element-loading-spinner="el-icon-loading">
                                  <el-option key="0" label="请选择..." value=""></el-option>
                                  <el-option
                                  v-for="item in yearList"
                                  :key="item.key"
                                  :label="item.label"
                                  :value="item.value"
                                  ></el-option>
                                </el-select>
                            </div>
                        </el-col>
                        <el-col :span="6" class="form-item">
                            <div class="label">专业名称</div>
                            <div class="input-box">
                                <el-input v-model="majorName" placeholder="请输入专业名称" class="form-control"></el-input>
                            </div>
                        </el-col>
                    </el-row>
                    <div class="table-container-body">
                        <el-table :data="majorTableData" height="500" stripe class="table-body" 
                            :header-cell-style="{background:'#e6e6e6', color: '#646464'}"
                            v-loading="majorTableLoading">
                            <el-table-column prop="schoolName" label="学校名称">
                            </el-table-column>
                            <el-table-column prop="majorName" label="专业名称">
                            </el-table-column>
                            <el-table-column prop="conatainMajor" label="所含专业" width="240">
                            </el-table-column>
                            <el-table-column prop="preferredRequest" label="首选科目要求" width="160">
                            </el-table-column>
                            <el-table-column prop="ReElectionRequest" label="再选科目要求" width="160">
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="table-container-footer">
                        <el-pagination
                            layout="total, prev, pager, next, sizes, jumper"
                            :page-sizes="[10, 50, 100, 500]"
                            :page-size="majorPageSize"
                            :total="majorTableTotal"
                            :current-page="majorPageNo"
                            @current-change="majorPageChange"
                            @size-change="majorSizeChange"
                        >
                        </el-pagination>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="选科科目专业查询" name="subjectTab">
                    <el-row>
                        <el-col :span="6" class="form-item">
                            <div class="label">省份</div>
                            <div class="input-box">
                                <el-select class="form-control" v-model="province"
                                v-loading="provinceListLoading"
                                element-loading-spinner="el-icon-loading">
                                  <el-option key="0" label="请选择..." value=""></el-option>
                                  <el-option
                                    v-for="item in provinceList"
                                    :key="item.key"
                                    :label="item.label"
                                    :value="item.value"
                                  ></el-option>
                              </el-select>
                            </div>
                        </el-col>
                        <el-col :span="6" class="form-item">
                            <div class="label">年份</div>
                            <div class="input-box">
                                <el-select class="form-control" v-model="year"
                                v-loading="yearListLoading"
                                element-loading-spinner="el-icon-loading">
                                  <el-option key="0" label="请选择..." value=""></el-option>
                                  <el-option
                                  v-for="item in yearList"
                                  :key="item.key"
                                  :label="item.label"
                                  :value="item.value"
                                  ></el-option>
                                </el-select>
                            </div>
                        </el-col>
                        <el-col :span="24" class="form-item">
                            <div class="label">科目</div>
                            <div class="input-box">
                                <el-checkbox-group v-model="subjectCheck" class="form-control"
                                v-loading="subjectListLoading"
                                element-loading-spinner="el-icon-loading">
                                    <el-checkbox v-for="item in subjectList" 
                                    :key="item.key" 
                                    :value="item.value"
                                    :label="item.label"
                                    :disabled="!item.availabe">
                                    </el-checkbox>
                                </el-checkbox-group>
                            </div>
                        </el-col>
                    </el-row>
                    <div class="table-container-body">
                        <el-table :data="subjectTableData" height="500" stripe class="table-body" 
                            :header-cell-style="{background:'#e6e6e6', color: '#646464'}"
                            v-loading="subjectTableLoading">
                            <el-table-column prop="schoolName" label="学校名称">
                            </el-table-column>
                            <el-table-column prop="majorName" label="专业名称">
                            </el-table-column>
                            <el-table-column prop="conatainMajor" label="所含专业" width="240">
                            </el-table-column>
                            <el-table-column prop="preferredRequest" label="首选科目要求" width="160">
                            </el-table-column>
                            <el-table-column prop="ReElectionRequest" label="再选科目要求" width="160">
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="table-container-footer">
                        <el-pagination
                            layout="total, prev, pager, next, sizes, jumper"
                            :page-sizes="[10, 50, 100, 500]"
                            :page-size="subjectPageSize"
                            :total="subjectTableTotal"
                            :current-page="subjectPageNo"
                            @current-change="subjectPageChange"
                            @size-change="subjectSizeChange"
                        >
                        </el-pagination>
                    </div>
                </el-tab-pane>
            </el-tabs>
            
          </div>
</template>
<script>
export default {
  name: "subject",
  components: {},
  props: {
    msg: String,
  },
  data() {
    return {
      tabSelect:"schoolTab",
      province:"",
      year:"",
      provinceListLoading:false,
      yearListLoading:false,
      yearList:[],
      provinceList:[],

      schoolPageNo: 1,
      schoolPageSize:10,
      schoolTableTotal:0,
      schoolName:"",
      schoolTableLoading:false,
      schoolTableData: [],

      majorPageNo: 1,
      majorPageSize:10,
      majorTableTotal:0,
      majorName:"",
      majorTableLoading:false,
      majorTableData: [],

      subjectPageNo: 1,
      subjectPageSize:10,
      subjectTableTotal:0,
      subjectCheck:[],
      subjectList:[],
      subjectListLoading:false,
      subjectTableLoading:false,
      subjectTableData: [],
    };
  },
  methods: {
    initCommonSelectData(){
      this.provinceListLoading = true;
      //获取省份
      this.$http.post(this.$apis.getProvinceOrCityList+"?type=province").then((res) => {
        this.provinceListLoading = false;
        if (res.data) {
          this.$store.commit("setProvinceList", res.data);
          this.provinceList = res.data.map(r=>{
            return {
              key:r.id,
              label:r.name,
              value:r.id
            }
          });
        }
      });
      this.yearListLoading = true;
      //获取年份
       this.$http.post(this.$apis.getSchoolCourseYears).then((res) => {
        this.yearListLoading = false;
        if (res.data) {
          this.yearList = res.data.map(r=>{
            return {
              key:r,
              label:r,
              value:r
            }
          });
        }
      });
    },
    //大学选科
    initSchoolSelectData(){
    },
    initSchoolTableData(){
      this.schoolTableLoading = true;
      var data={
          currentPage: this.schoolPageNo,
          pageSize: this.schoolPageSize,
          province: this.province,
          schoolName: this.schoolName,
          year: this.year
      }
      this.$http.post(this.$apis.getSubjectSchool,data).then((res) => {
        this.schoolTableLoading = false;
        if (res.data) {
          this.schoolTableData = res.data.list.map(r=>{
            //todo
            return {
              majorName: "法学",
              conatainMajor: "-",
              preferredRequest:"物理/历史",
              ReElectionRequest:"不限",
            }
          });
          this.schoolTableTotal = res.data.total;
        }else{
          this.schoolTableData = [
            {
              majorName: "法学",
              conatainMajor: "-",
              preferredRequest:"物理/历史",
              ReElectionRequest:"不限",
            }
          ];
        }
      });
    },
    //专业选科
    initMajorSelectData(){
    },
    initMajorTableData(){
      this.majorTableLoading = true;
      var data={
          currentPage: this.majorPageNo,
          pageSize: this.majorPageSize,
          province: this.province,
          subjectName: this.majorName,
          year: this.year
      }
      this.$http.post(this.$apis.getSubjectSchool,data).then((res) => {
        this.majorTableLoading = false;
        if (res.data) {
          this.majorTableData = res.data.list.map(r=>{
            //todo
            return {
              schoolName:"湖南工业职业技术学院",
              majorName: "产品艺术设计",
              conatainMajor: "-",
              preferredRequest:"物理/历史",
              ReElectionRequest:"不限",
            }
          });
          this.majorTableTotal = res.data.total;
        }else{
          this.majorTableData = [
            {
              schoolName:"湖南工业职业技术学院",
              majorName: "产品艺术设计",
              conatainMajor: "-",
              preferredRequest:"物理/历史",
              ReElectionRequest:"不限",
            }
          ];
        }
      });
    },
    
    //科目专业
    initSubjectSelectData(){
      this.subjectListLoading = true;
      this.$http.post(this.$apis.getDictionary+"?type=course").then(r=>{
        this.subjectListLoading = false;
        if(r.data){
          this.subjectList = r.data.map(m=>{
            return {
              key:m.id,
              value:m.code,
              label:m.name,
              availabe:true
            }
          });
        }
      });
    },
    initSubjectTableData(){
      this.subjectTableLoading = true;
      var data={
          courseTypeCode: this.subject,
          currentPage: this.subjectPageNo,
          pageSize: this.subjectPageSize,
          province: this.province,
          year: this.year
      }
      this.$http.post(this.$apis.getSubjectSchool,data).then((res) => {
        this.subjectTableLoading = false;
        if (res.data) {
          this.subjectTableData = res.data.list.map(r=>{
            //todo
            return {
              schoolName:"湖南工业职业技术学院",
              majorName: "产品艺术设计",
              conatainMajor: "-",
              preferredRequest:"物理/历史",
              ReElectionRequest:"不限",
            }
          });
          this.subjectTableTotal = res.data.total;
        }else{
          this.subjectTableData = [
            {
              schoolName:"湖南工业职业技术学院",
              majorName: "产品艺术设计",
              conatainMajor: "-",
              preferredRequest:"物理/历史",
              ReElectionRequest:"不限",
            }
          ];
        }
      });
    },
    handleTabClick(){
        if(this.tabSelect == "schoolTab"){
          this.initSchoolTableData();
        }else if(this.tabSelect =="majorTab"){
          this.initMajorTableData();
        }else if(this.tabSelect =="subjectTab"){
          this.initSubjectSelectData();
          this.initSubjectTableData();
        }
        this.initCommonSelectData();
        this.province = "";
        this.year = "";
        this.majorName ="";
        this.schoolName = "";
        //this.subjectCheck = [];
    },
    handleSearchClick(){
      console.log(this.subjectCheck);
        if(this.tabSelect == "schoolTab"){
          this.initSchoolTableData();
        }else if(this.tabSelect =="majorTab"){
          this.initMajorTableData();
        }else if(this.tabSelect =="subjectTab"){
          this.initSubjectTableData();
        }
    },
    schoolPageChange(val) {
      this.schoolPageNo = val;
      this.initSchoolTableData();
    },
    schoolSizeChange(val){
      this.schoolPageSize = val;
      this.initSchoolTableData();
    },
    majorPageChange(val) {
      this.majorPageNo = val;
      this.initMajorTableData();
    },
    majorSizeChange(val){
      this.majorPageSize = val;
      this.initMajorTableData();
    },
    subjectPageChange(val) {
      this.subjectPageNo = val;
      this.initSubjectTableData();
    },
    subjectSizeChange(val){
      this.subjectPageSize = val;
      this.initSubjectTableData();
    },
  },
  mounted(){
    this.initCommonSelectData();
    this.initSchoolTableData();
  },
};
</script>
<style lang="less" scoped>
.subject {
  margin: 20px;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  border-radius: 5px;
  border: 1px solid rgba(230, 230, 230, 100);

  .top-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 10px;
    border-bottom: 1px solid rgba(230, 230, 230, 100);

    .top-title {
      color: rgba(16, 16, 16, 100);
      font-size: 20px;
      font-weight: 700;
      padding-left: 20px;
      position: relative;
    }
    .top-title::after {
      position: absolute;
      display: block;
      content: "";
      top: 50%;
      left: 5px;
      transform: translateY(-50%);
      height: 27px;
      width: 3px;
      background-color: #ff450b;
    }

    .btn-box {
      display: flex;
      align-items: center;
      .btn {
        line-height: 32px;
        width: 95px;
        border-radius: 4px;
        background-color: rgba(255, 69, 11, 100);
        text-align: center;
        color: #fff;
        margin-left: 15px;
        cursor: pointer;
        font-size: 14px;
      }
    }
  }

  .form-group{
      padding: 20px 20px;
    .form-item {
      display: flex;
      align-items: center;
      padding: 15px 0;
      .label {
        display: flex;
        justify-content: flex-end;
        color: rgba(100, 100, 100, 100);
        font-size: 14px;
        width: 70px;
        padding-right:10px;
      }
      .input-box {
          display: flex;
          width: calc(100% - 100px);
      }
      .required-label{
            color: rgb(255, 0, 0);
      }
      .action-link{
          font-size: 16px;
          cursor:pointer;
          color: #ff450b;
          padding-right: 20px;
      }
    }
  }
  .form-control{
      width: 100%;
  }

  .table-container-body {
    .table-body{
      width: 100%;
      overflow: auto;
      border: 1px solid #e6e6e6;
    }
    .logo{
      height: 48px;
      width: 48px;
    }
    .label{
      display: inline-block;
      border: 1px solid #646464;
      font-size: 12px;
      margin-right: 2px;
      border-radius: 4px;
      padding: 0 5px;
      line-height: 14px;
    }
    .table-btn-box {
      display: flex;
      align-items: center;
      justify-content: space-around;
      .table-btn {
        cursor: pointer;
        width: 60px;
        line-height: 28px;
        border-radius: 14px;
        color: rgba(121, 160, 241, 100);
        font-size: 12px;
        text-align: center;
        border: 1px solid rgba(121, 160, 241, 100);
      }
    }
  }
  .table-container-footer{
    padding: 20px 0;
  }
  
}

</style>